import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Dialog } from "primereact/dialog";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import Select from "react-select";
import { FilterMatchMode } from "primereact/api";
import StageDependencyGraph from "./StageDependencyGraph.js";
import { useLoader } from "../../context/Loader/LoaderProvider.js";
import Loader from "../../context/Loader/Loader.js";

const AddEditProduct = () => {
  const { id } = useParams(); // Get the id from the URL params
  const navigate = useNavigate(); // Get the navigate function from the router
  const [plantsData, setPlantsData] = useState([]);
  const { isLoading, setIsLoading } = useLoader(); // Get the isLoading state and setIsLoading function from LoaderProvider
  const [stagesData, setStagesData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [stageId, setStageId] = useState(null);
  const [supervisors, setSupervisors] = useState([]);
  const { authPermissions } = usePermissions();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const { userId } = JSON.parse(localStorage.getItem("userData"));

  // Define validation schema using Yup
  const productSchema = Yup.object({
    product_name: Yup.string().required("Stage Template Name is required"),
    product_category_id: Yup.mixed().required("Product Group is required"),
    // product_description: Yup.string().required('Product Description is required'),
  });

  // Define initial form values
  const productForm = {
    product_name: "",
    product_category_id: "",
    product_description: "",
  };

  const stageSchema = Yup.object({
    stage_name: Yup.string().required("Stage Name is required"),
    user_id: Yup.string().required("Supervisor is required"),
    days: Yup.string().required("No. of days is required"),
  });

  // Define initial form values
  const stageForm = {
    stage_name: "",
    stage_description: "",
    product_id: id,
    user_id: "",
    days: "",
    parent_stage_ids: [],
  };

  /**
   * Fetches the plants data from the backend and updates the state with the response.
   * This function is called when the component mounts to initialize the plants data.
   *
   * @async
   * @function fetchPlantData
   * @returns {Promise<void>} - Resolves when the data has been fetched and the state has been updated.
   */

  const fetchPlantData = async () => {
    setIsLoading(true); // Set isLoading to true before fetching data

    try {
      // Fetch the plants data from the backend
      const response = await axiosPrivate.get("product_category");

      // If the status is successful, update the plants data
      if (response.status === 200) {
        setPlantsData(response.data?.data);
      }
    } catch (error) {
      // If there is an error, show an error toast
      showErrorToast(error.message);
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  useEffect(() => {
    fetchPlantData();
  }, []);

  // Function to handle plant deletion
  const stageDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`stage/${id}`);
      if (response.status === 200) {
        fetchStagesData();
        showSuccessToast("Stage deleted successfully");
      }
    } catch (error) {
      showErrorToast(
        error?.response?.data?.errors?.length
          ? error?.response?.data?.errors[0]?.msg
          : "Something went wrong!"
      );
    }
  };

  // Fetch stages data from the server
  const fetchStagesData = async () => {
    try {
      setIsLoading(true); // Set isLoading to true before fetching data
      if (id) {
        const response = await axiosPrivate.get(`stage/product/${id}`);
        if (response.status === 200) {
          setStagesData(response.data?.data);
        }
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      } else if (error.response?.status === 404) {
        setStagesData([]);
      }
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };
  const fetchSupervisorsData = async () => {
    try {
      setIsLoading(true); // Set isLoading to true before fetching data
      const response = await axiosPrivate.get(`users/supervisors/${userId}`);
      if (response.status === 200) {
        setSupervisors(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };
  useEffect(() => {
    fetchSupervisorsData();
  }, []);

  /**
   * Handle the click event on the stage.
   *
   * @param {number} id - The id of the stage.
   * @return {Promise<void>} - A promise that resolves when the function is done.
   */
  const handleClick = async (id) => {
    // Set the stageId to the provided id
    await setStageId(id);

    // Show the stage form
    setVisible(true);

    // Only fetch the stage data if an id is provided
    if (id) {
      // Wait for the getStage function to complete
      await getStage(id);
    }
  };

  // Load plant data on component mount
  useEffect(() => {
    fetchStagesData();
  }, []);

  const stagesListData = stagesData?.map((value, index) => {
    let buttons = [];

    // Add edit button
    buttons.push(
      <Link
        key={`editButton_${value.id}`}
        onClick={() => handleClick(value.id)}
        style={{ border: "none", background: "none", padding: "6px" }}
        title='Edit'
      >
        <FaEdit
          color='green'
          size={13}
        />
      </Link>
    );

    // Add delete button
    buttons.push(
      <button
        key={`deleteButton_${value.id}`}
        type='button'
        onClick={() => stageDeleteHandler(value.id)}
        title='Delete'
        style={{ border: "none", background: "none", padding: "6px" }}
      >
        <FaTrash
          color='red'
          size={13}
        />
      </button>
    );

    value["action"] = buttons.length > 0 ? buttons : "-";

    return {
      ...value,
      index: index + 1,
      action: buttons.length > 0 ? buttons : "-",
    };
  });

  // const stages = [
  //   { id: "s1", name: "Sealing", parent: "s2" },
  //   { id: "s2", name: "Cutting", parent: null },

  //   { id: "s3", name: "Final Assembly", parent: "s1" },
  //   { id: "s6", name: "Workshop", parent: "s5" },
  //   { id: "s6", name: "Workshop", parent: "s3" },
  //   { id: "s4", name: "Final QC", parent: "s6" },
  //   { id: "s5", name: "CNC", parent: null },
  // ];

  const stages = [];

  stagesData.forEach((stage) => {
    if (stage?.parent_stage_ids.length > 0) {
      const arr = stage?.parent_stage_ids?.map((item) => ({
        ...item,
        id: item.id.toLocaleString(),
        parent: item.parent.toLocaleString(),
      }));

      stages.push(...arr);
    } else {
      stages.push({
        id: stage.id.toLocaleString(),
        name: stage.stage_name,
        parent: null,
      });
    }
  });

  useEffect(() => {
    const getProduct = async () => {
      try {
        if (id) {
          const res = await axiosPrivate.get(`product/${id}`);
          setValues(res?.data?.data);
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };
    if (id) {
      getProduct();
    }
  }, [id]);
  // Formik hook to manage form state, validation, and submission
  /**
   * Initializes and manages the state, validation, and submission of a form using the Formik library.
   * This code is likely part of a larger component that handles the creation or editing of a product.
   * The `useFormik` hook is used to manage the form state, including the initial values, validation schema,
   * and the submit handler function.
   *
   * The returned object from `useFormik` contains the following properties:
   * - `values`: The current values of the form fields.
   * - `handleBlur`: A function to handle the `onBlur` event of form fields.
   * - `handleChange`: A function to handle the `onChange` event of form fields.
   * - `handleSubmit`: A function to handle the form submission.
   * - `setFieldValue`: A function to set the value of a specific form field.
   * - `errors`: An object containing validation errors for the form fields.
   * - `touched`: An object indicating which form fields have been touched (i.e., the user has interacted with them).
   * - `setValues`: A function to set the initial values of the form.
   */
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: productForm,
    validationSchema: productSchema,
    onSubmit: async (values) => {
      try {
        if (id) {
          await axiosPrivate.put(`product/${id}`, values);
          navigate("/products");
          showSuccessToast("Stage template updated successfully");
        } else {
          await axiosPrivate.post("product", {
            ...values,
            product_category_id: values?.product_category_id,
          });
          navigate("/products");
          showSuccessToast("Stage template created successfully");
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    },
  });

  // get stage Data by stageId

  const getStage = async (id) => {
    try {
      if (id) {
        const res = await axiosPrivate.get(`stage/${id}`);
        stageFormik.setValues({
          ...res?.data?.data,
          user_id: res?.data?.data?.user_id?.toLocaleString(),
        });
      }
    } catch (err) {
      showErrorToast(err.message);
    }
  };

  /**
   * Handles the submission of the stage form.
   *
   * @param {Object} values - The form values.
   * @param {string} values.stage_name - The name of the stage.
   * @param {number} values.product_id - The ID of the product.
   * @param {number} values.days - The number of days for the stage.
   * @param {Array<{value: number}>} values.parent_stage_ids - The IDs of the parent stages.
   * @param {string} values.stage_description - The description of the stage.
   * @param {string} values.user_id - The ID of the user.
   * @returns {Promise<void>} - A promise that resolves when the stage is updated or created.
   */
  const stageFormik = useFormik({
    initialValues: stageForm,
    validationSchema: stageSchema,
    onSubmit: async (values) => {
      try {
        const {
          stage_name,
          product_id,
          days,
          parent_stage_ids,
          stage_description,
          user_id,
        } = values;
        if (stageId) {
          const response = await axiosPrivate.put(`stage/${stageId}`, {
            stage_name,
            product_id,
            parent_stage_ids: parent_stage_ids?.map((item) => item.value),
            stage_description,
            days,
            user_id,
          });

          if (response?.status === 200) {
            showSuccessToast("Stage updated successfully");
            fetchStagesData();
            navigate(`/products/edit/${id}`);
            setVisible(false);
            setStageId(null);
            stageFormik.resetForm();
          }
        } else {
          const newPayload = {
            ...values,
            parent_stage_ids: values.parent_stage_ids.map((item) => item.value),
          };
          const response = await axiosPrivate.post("stage", newPayload);

          if (response?.status === 201) {
            showSuccessToast("Stage added successfully");
            fetchStagesData();
            navigate(`/products/edit/${id}`);
            setVisible(false);
            stageFormik.resetForm();
          }
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    },
  });
  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };
  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='d-flex justify-content-between w-100'>
        {/* Render user management section if user has permission */}
        {authPermissions?.includes("Stages-Create") && (
          <button
            className='btn btn-primary'
            onClick={() => setVisible(!visible)}
            data-toggle='modal'
            data-target='#exampleModal'
          >
            <i className='fe fe-plus mr-2' />
            Add
          </button>
        )}
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader();

  const renderError = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? (
      <div style={{ color: "red", fontSize: "12px" }}>{errors[fieldName]}</div>
    ) : null;
  };

  const stagerenderError = (fieldName) => {
    return stageFormik.touched[fieldName] && stageFormik.errors[fieldName] ? (
      <div style={{ color: "red", fontSize: "12px" }}>
        {stageFormik.errors[fieldName]}
      </div>
    ) : null;
  };

  const dependenciesTemplate = (rowData) => {
    return (
      <>
        {rowData?.parent_stage_ids?.length ? (
          rowData?.parent_stage_ids?.map((item) => (
            <span
              key={item.id}
              className=' ml-2 badge badge-danger p-2'
            >
              {stagesData.find((stage) => stage.id === item.parent)?.stage_name}
            </span>
          ))
        ) : (
          <span className='ml-2 badge badge-default p-2'>Independent</span>
        )}
      </>
    );
  }; 
  const selectedProduct = plantsData.find(
    (product_category) => product_category.id === values.product_category_id
  );

  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <div className='content'>
          <div className='section-body mt-2'>
            <div className='card'>
              <form onSubmit={handleSubmit}>
                <h5 className='m-3'>
                  <strong>Stage Template</strong>
                </h5>
                <div className='card-body '>
                  <div
                    className='row clearfix'
                    // style={{ width: "30%" }}
                  >
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label className='form-label'>
                          Stage Template Name
                        </label>
                        <input
                          id='product_name'
                          name='product_name'
                          type='text'
                          className='form-control'
                          placeholder='Stage Template Name *'
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                            setFieldValue(
                              "product_name",
                              values.product_name.trim().toUpperCase()
                            );
                          }}
                          value={values.product_name}
                        />
                      </div>
                      {renderError("product_name")}
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label className='form-label'>
                          {" "}
                          Select Product Group
                        </label>
                        <Select
                          options={plantsData.map((product_category) => ({
                            value: product_category.id,
                            label: `${product_category.product_category_name} - (${product_category.plant_name})`,
                          }))}
                          name='product_category_id'
                            value={
                              selectedProduct ? {
                                value: selectedProduct.id,
                                label: `${selectedProduct.product_category_name} - (${selectedProduct.plant_name})`,
                              } : null
                            }
                          onChange={(selectedOption) =>
                            setFieldValue("product_category_id", selectedOption?.value)
                          }
                          onBlur={handleBlur}
                          placeholder='--Select Product Group*--'
                          isSearchable
                        />
                      </div>
                      {renderError("product_category_id")}
                    </div>
                    <div className='col-md-12 col-sm-12'>
                      <div className='form-group'>
                        <label className='form-label'> Description</label>
                        <textarea
                          name='product_description'
                          id='product_description'
                          placeholder='Stage template Description '
                          rows={3}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.product_description}
                        ></textarea>
                      </div>
                      {renderError("productdescription")}
                    </div>
                    <div className='col-12 text-right'>
                      <hr className='mt-4' />

                      <button
                        type='button'
                        id='button_1'
                        className='btn btn-secondary mx-1 '
                        data-dismiss='modal'
                        onClick={() => navigate("/products")}
                      >
                        CLOSE
                      </button>
                      <button
                        type='submit'
                        id='button_2'
                        className='btn btn-primary'
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {id ? (
            <div className='section-body'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='mt-2 mb-4'>
                    <strong>Stages</strong>
                  </h5>

                  <div className='row clearfix mt-2'>
                    <div className='table-responsive'>
                      <Dialog
                        header='Add Stage'
                        visible={visible}
                        style={{ width: "35vw" }}
                        onHide={() => {
                          setStageId(null);
                          stageFormik.resetForm();
                          setVisible(false);
                        }}
                      >
                        <form
                          className='form-horizontal'
                          onSubmit={stageFormik.handleSubmit}
                        >
                          <div className='form-group'>
                            <label className='col-sm control-label'>
                              Stage Name
                            </label>
                            <div className='col-sm-12'>
                              <input
                                id='stage_name'
                                name='stage_name'
                                type='text'
                                className='form-control'
                                placeholder='Stage-Name *'
                                onChange={stageFormik.handleChange}
                                onBlur={(e) => {
                                  stageFormik.handleBlur(e);
                                  stageFormik.setFieldValue(
                                    "stage_name",
                                    stageFormik.values.stage_name
                                      .trim()
                                      .toUpperCase()
                                  );
                                }}
                                value={stageFormik.values?.stage_name}
                              />
                              {stagerenderError("stage_name")}
                            </div>
                          </div>
                          <div className='form-group'>
                            <label className='col-sm control-label'>
                              Parent Stages
                            </label>
                            <div className='col-sm-12'>
                              <Select
                                options={stagesData
                                  ?.filter(
                                    (stage) =>
                                      Number(stage?.id) !== Number(stageId)
                                  )
                                  ?.map((stage) => ({
                                    value: stage?.id,
                                    label: stage?.stage_name,
                                  }))}
                                name='parent_stage_ids'
                                value={stageFormik.values?.parent_stage_ids?.map(
                                  (stage) => ({
                                    value: stage?.value,
                                    label: stage?.label,
                                  })
                                )}
                                isMulti
                                onChange={(selectedOptions) => {
                                  stageFormik.setFieldValue(
                                    "parent_stage_ids",
                                    selectedOptions?.map((option) => ({
                                      value: option.value,
                                      label: option.label,
                                    }))
                                  );
                                }}
                              />
                              {stagerenderError("parent_stage_ids")}
                            </div>
                          </div>

                          <div className='form-group'>
                            <label className='col-sm control-label'>Days</label>
                            <div className='col-sm-12'>
                              <input
                                id='days'
                                name='days'
                                type='number'
                                className='form-control'
                                placeholder='No. of days required for this stage'
                                onChange={stageFormik.handleChange}
                                onBlur={stageFormik.handleBlur}
                                value={stageFormik.values?.days}
                              />
                              {stagerenderError("days")}
                            </div>
                          </div>
                          <div className='col-md-12 col-sm-12'>
                            <div className='form-group'>
                              <label className='col-sm control-label'>
                                Stage Supervisor
                              </label>
                              <Dropdown
                                name='user_id'
                                id='user_id'
                                options={supervisors}
                                value={stageFormik.values?.user_id}
                                onChange={(e) =>
                                  stageFormik.setFieldValue("user_id", e.value)
                                }
                                placeholder='Select Supervisor'
                                optionLabel='label'
                                optionValue='value'
                                filter
                                showClear={stageFormik.values?.user_id?.length}
                                filterBy='label'
                                className='w-100'
                              />
                              {stagerenderError("user_id")}
                            </div>
                          </div>
                          <div className='col-sm-12'>
                            <div className='form-group'>
                              <label className='col-sm control-label'>
                                Stage Description
                              </label>
                              <textarea
                                name='stage_description'
                                id='stage_description'
                                placeholder='Stage-Description '
                                rows={3}
                                onChange={stageFormik.handleChange}
                                onBlur={stageFormik.handleBlur}
                                value={stageFormik.values?.stage_description}
                              ></textarea>
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='col-sm-12 col-sm-offset-2 text-right pt-2'>
                              <button
                                type='submit'
                                id='button_2'
                                className='btn btn-primary'
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </Dialog>

                      <DataTable
                        value={stagesListData}
                        dataKey='id'
                        header={header}
                        filters={filters}
                        onFilter={(e) => setFilters(e.filters)}
                        paginator
                        rows={10}
                      >
                        <Column
                          field='index'
                          header='Sr. No.'
                          headerStyle={{ width: "10%" }}
                          bodyStyle={{ textAlign: "left", paddingLeft: "1%" }}
                          sortable
                        />
                        <Column
                          field='stage_name'
                          header='Stage Name'
                          sortable
                        />
                        <Column
                          field='days'
                          header='Days'
                          sortable
                        />
                        <Column
                          field='dependencies'
                          header='Dependencies'
                          body={dependenciesTemplate}
                          sortable
                        />
                        <Column
                          field='supervisor_name'
                          header='Supervisor'
                          sortable
                        />
                        <Column
                          field='action'
                          header='Action'
                          sortable
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {stages?.length ? (
            <div className='section-body mt-2'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='mt-2 mb-4'>
                    <strong>Dependency Graph</strong>
                  </h5>
                  <div className='container'>
                    <StageDependencyGraph stages={stages} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
export default AddEditProduct;
